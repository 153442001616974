import request from '@/utils/request'

export function getVocationalHighSchoolDepartmentById(data) {
  return request({
    url: `/api/VocationalHighSchoolDepartments/GetVocationalHighSchoolDepartmentById/${data}`,
    method: 'get',
  })
}
export function getVocationalHighSchoolDepartments() {
  return request({
    url: '/api/VocationalHighSchoolDepartments/GetVocationalHighSchoolDepartments',
    method: 'get',
  })
}

export function addVocationalHighSchoolDepartment(data) {
  return request({
    url: '/api/VocationalHighSchoolDepartments/AddVocationalHighSchoolDepartment',
    method: 'post',
    data,
  })
}

export function updateVocationalHighSchoolDepartment(data) {
  return request({
    url: '/api/VocationalHighSchoolDepartments/UpdateVocationalHighSchoolDepartment',
    method: 'put',
    data,
  })
}
export function deleteVocationalHighSchoolDepartment(data) {
  return request({
    url: `/api/VocationalHighSchoolDepartments/DeleteVocationalHighSchoolDepartment?vocationalHighSchoolId=${data}`,
    // /api/VocationalHighSchoolDepartments/DeleteVocationalHighSchoolDepartment?vocationalHighSchoolId=12
    method: 'delete',
  })
}
